import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class UrlApiService {

  constructor() { }
  _getUrl(){
      //return 'http://127.0.0.1:8000/api/';    
      //  return 'https://vridez.com/admin/api/';
      //return 'http://aol.nzm.mybluehostin.me/admin/api/' ;
      return 'https://app.vridez.com/admin/api/';
  }
}
